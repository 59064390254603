import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Seo from "../components/Seo";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import HeroSvg from "../inline_svg/hero.svg";
import RequirementsIconSvg from "../inline_svg/requirements-icon.svg";
import DetailsIconSvg from "../inline_svg/details-icon.svg";
import LearnMoreSvg from "../inline_svg/learn-more.svg";
import BldaiSvg from "../inline_svg/bldai.svg";
import CeloSvg from "../inline_svg/celo.svg";
import BldaiLogoSvg from "../inline_svg/bldai-logo.svg";
import CeloLogoSvg from "../inline_svg/celo-logo.svg";
import FaqsSvg from "../inline_svg/faqs.svg";
import "../styles/index.scss";

import useSiteMetadata from "../hooks/useSiteMetadata";

const IndexPage = ({ location: { pathname } }) => {
  const { siteUrl } = useSiteMetadata();
  return (
    <>
      <Seo
        structuredData={{
          "@context": "https://schema.org/",
          "@type": "Organization",
          url: `${siteUrl}${pathname}`,
          name: "bld.ai Web3 Studio",
        }}
      />
      <TopNav />
      <main id="home-page" className="mt-n8">
        <section id="hero" className="mb-n8 mb-md-n7 mb-lg-n5 mb-xl-0">
          <Container className="hero-content mb-0 mb-lg-6 mb-xl-8">
            <Row className="align-items-center justify-content-center">
              <Col
                xs="12"
                lg="8"
                xl="7"
                xxl="6"
                className="text-center text-lg-start"
              >
                <h1 className="display-1 mb-5">
                  Introducing
                  <br />
                  bld.ai Web3 Studio
                </h1>
                <Button
                  className="ms-5 me-5 ms-lg-0 mb-5"
                  as={Link}
                  to="/apply/"
                  size="lg"
                  variant="dark"
                >
                  Apply Now
                </Button>
                <Button
                  className="ms-5 me-5 mb-5"
                  href="#learn-more"
                  size="lg"
                  variant="outline-dark"
                >
                  Learn More
                </Button>
              </Col>
              <Col xs="8" lg="4" xl="5" xxl="6">
                <HeroSvg className="illustration" />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="learn-more" className="mb-8">
          <Container>
            <Row className="align-items-center justify-content-center mb-6">
              <Col xs="12">
                <p className="fs-1 fw-bold mb-5">
                  As an active member of the Celo ecosystem from the early days,
                  we have been privileged to collaborate with many of the
                  greatest products and founding teams in Web3, ranging from p2p
                  marketplaces to DeFi protocols to NFTs.
                </p>
                <p className="fs-1 fw-bold">
                  Today we are excited to announce that we’ll be turbo-charging
                  those efforts with a new program called bld.ai Web3 Studio,
                  which we hope can accelerate 1,000s of amazing new products to
                  launch and make an impact in the world. The first cohort, with
                  up to 20 participating startups will be launching this month.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mb-6 gx-md-6 gx-lg-8 gx-xl-10">
              <Col xs="12" md="6" className="mb-6">
                <DetailsIconSvg className="h3-icon mb-5" />
                <h3 className="mb-5">Web3 Studio Details</h3>
                <ul className="fs-2 fw-bold">
                  <li className="mb-4">
                    Typically four months in length, rolling admissions
                    through December 2022.
                  </li>
                  <li className="mb-4">
                    bld.ai provides a dedicated team (product manager,
                    designers, engineers) to work alongside founder(s), in
                    return for common stock equity / token grant (YC SAFE with 1M cap).
                  </li>
                  <li className="mb-4">
                    Access to mentor network from the Celo ecosystem.
                  </li>
                </ul>
              </Col>
              <Col xs="12" md="6" className="mb-6">
                <RequirementsIconSvg className="h3-icon mb-5" />
                <h3 className="mb-5">Requirements</h3>
                <ul className="fs-2 fw-bold">
                  <li className="mb-4">
                    The organization is set up / incorporated.
                  </li>
                  <li className="mb-4">At least one full-time founder.</li>
                  <li className="mb-4">Building on Celo.</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center mb-6">
              <Col xs="12" className="text-center">
                <LearnMoreSvg className="mb-6" />
                <p className="fs-1 fw-bold text-start mb-6">
                  Let’s build world-class products together - all you need to
                  bring is your idea and passion to make change in the world.
                  Join us and together we can create a financial system that
                  creates conditions for prosperity for everyone.
                </p>
                <Button
                  className="apply-button text-light"
                  as={Link}
                  to="/apply/"
                  size="lg"
                  variant="primary"
                >
                  Apply Now
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="about-us" className="mb-8">
          <Container>
            <h2 className="text-md-center mb-7">About Us</h2>
            <Row className="align-items-center justify-content-center mb-7 gx-lg-8">
              <Col xs="12" md="8">
                <BldaiLogoSvg className="mb-6" />
                <p className="fs-2">
                  <strong>bld.ai</strong> is a rapidly growing design and
                  development team with over 100 engineers, designers, and
                  product managers. We work with amazing organizations, from
                  stealth startups to global multinationals.
                  <br />
                  You can learn more here:{" "}
                  <a
                    href="https://www.bld.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.bld.ai/
                  </a>
                </p>
              </Col>
              <Col xs="8" md="4" className="d-none d-md-block">
                <BldaiSvg className="illustration" />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center mb-7 gx-lg-8">
              <Col xs="8" md="4" className="d-none d-md-block">
                <CeloSvg className="illustration" />
              </Col>
              <Col xs="12" md="8" className="text-md-end">
                <CeloLogoSvg className="mb-6" />
                <p className="fs-2 text-start">
                  <strong>Celo</strong> is a carbon-negative, open-source,
                  EVM-compatible L1 blockchain optimized for mobile with a rich
                  ecosystem of decentralized finance (DeFi) applications and a
                  goal to make Web3 apps and digital assets easily accessible to
                  everyone with a phone.
                  <br />
                  Learn more at{" "}
                  <a href="https://celo.org/" target="_blank" rel="noreferrer">
                    https://celo.org/
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://docs.celo.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://docs.celo.org/
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="faqs" className="pb-8">
          <Container>
            <h2 className="mb-7">FAQs</h2>
            <Row className="justify-content-center">
              <Col xs="12" md="8" lg="6">
                <ol>
                  <li className="mb-6">
                    <h4 className="mb-3">
                      Will Web3 Studio take tokens/equity out of my startup?
                    </h4>
                    <p className="fw-lighter">
                      Yes! We want to be fully aligned with you. Depending on
                      your needs and stage bld.ai Web3 Studio will take a small
                      common stock and/or token grant.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h4 className="mb-3">
                      What does bld.ai get out of this beyond small
                      participation in my business?
                    </h4>
                    <p className="fw-lighter">
                      bld.ai is a trusted partner of many organizations in the
                      Celo ecosystem and also of many clients globally. We get
                      to be part of your success story and help you get to
                      market faster whilst ensuring high quality and resilient
                      products are deployed to your users. Web3 Studio is partly
                      funded by the Celo Foundation.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h4 className="mb-3">
                      How does this compare to other incubators (e.g.,
                      Y&#8209;Combinator)?
                    </h4>
                    <p className="fw-lighter">
                      We are generally seen as a complement to existing
                      incubators and/or aim to help at the earliest stage ahead
                      of joining accelerator programs. Unlike other programs, we
                      are hands-on from crafting your designs in Figma to coding
                      your smart contracts in Solidity.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h4 className="mb-3">
                      I’m building a Web3 DeFi startup but not on Celo, do I
                      still qualify?
                    </h4>
                    <p className="fw-lighter">
                      No, sorry. bld.ai is still available as a design studio
                      and development shop, but for now, we want to keep Web3
                      Studio focused and for Celo projects only, to maximize
                      synergies and collaboration.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h4 className="mb-3">What have you built before?</h4>
                    <p className="fw-lighter">
                      Our blockchain portfolio includes mobile wallets, web
                      tools for payment, disbursements, tracking, NFT platforms,
                      and many more.
                    </p>
                  </li>
                </ol>
              </Col>
              <Col xs="12" md="4" lg="6" className="d-none d-md-block ps-xl-8">
                <FaqsSvg className="illustration" />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
